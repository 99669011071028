// Import all plugins
import "./import-jquery";
import "./import-bootstrap";
import fancybox from '@fancyapps/fancybox';


import {getBreakPointName, isWithinBreakpoint} from "./checkBootstrapBreakpoint";

$(function() {

    ds81_upgrade_links_to_fancybox();

});


function ds81_upgrade_links_to_fancybox(){


    // Upgrade links to use fancybox:
    $('a.fb').each(function () {
        if(!$(this).hasClass('ds-fancybox-init'))
        {
            $(this).addClass('ds-fancybox-init')
            $(this).attr('data-fancybox', 'ajaxbox' + $(this).index());
            $(this).attr('data-type', 'ajax');
            $(this).attr('data-src', $(this).attr('href'));
            $(this).attr('data-filter', '#mainContent');
        }
    });

    $().fancybox({
        selector  : '[data-fancybox]',
        beforeShow : function() {

            $.fancybox.getInstance().hideControls();
        },
        afterShow : function() {

            $.fancybox.getInstance().hideControls();

            ds81_upgrade_links_to_fancybox();

        }
    });

}
